import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="footer-container" >
        <a href="http://franshiromedia.com" target="_blank" rel="noopener noreferrer">&#169; by FranshiroMedia 2022</a>
      </div>
    );
  }
}

export default Footer;
