import React from 'react'
import ProductModal from '../productModal'
import { numberWithCommas } from '../../helper/function'


function ProductCard ({ 
  name, 
  img, 
  brand, 
  warehouse, 
  region, 
  price, 
  desc 
}) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal(e) {
    e.stopPropagation();
    setIsOpen(false);
  }
  return(
    <div className='product-card-container' onClick={openModal}>
      <div className='product-card-img'>
        <img src={`https://staging-cuan.awalmula.co/pub/media/catalog/product/cache/06a2b2d0b3b3bcee577608c878a0377c${img}`} alt="" />
      </div>
      <div className='product-card-content'>
        <div className='product-card-title'>
          <p className='small-grey-text'>{ brand }</p>
          <p className='title'>{ name }</p>
        </div>
        <div className='product-card-warehouse'>
          <p className='small-grey-text'>{ region }</p>
        </div>
        <div className='product-card-price'>
          Rp { numberWithCommas(price) }
        </div>
      </div>
      <ProductModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        img={img}
        name={name}
        brand={brand}
        warehouse={warehouse}
        price={price}
        region={region}
        desc={desc}
      />
    </div>
  )
}

export default ProductCard