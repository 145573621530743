import React from 'react'
import ReactModal from 'react-modal'
import ReactHtmlParser from 'react-html-parser';
import { numberWithCommas } from '../helper/function'


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

ReactModal.setAppElement('#root');

function ProductModal({
  isOpen, 
  closeModal,
  img, 
  brand, 
  name,
  warehouse, 
  price,
  desc,
  region
}) {
  return(
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      {/* <h2>{ product?.name }</h2> */}
      <div className='product-modal-wrapper'>
        <button className='btn-modal-close' onClick={closeModal}>x</button>
        <div className="product-detail-wrapper">
          <div className="product-detail-img">
            <img src={`https://staging-cuan.awalmula.co/pub/media/catalog/product/cache/06a2b2d0b3b3bcee577608c878a0377c${img}`} alt="" />
          </div>
          <div className="product-detail-desc" style={{padding: '20px 5px'}}>
            <div className='subtitle'>{ brand }</div>
            <h2 className='mh10'>{ name }</h2>
            <div className='subtitle mh5'>Warehouse { warehouse } | Kota { region }</div>
            <div className='title mh10'>
              Harga: Rp { numberWithCommas(price) }
            </div>
            <div>
              <h3>Deskripsi</h3>
              { ReactHtmlParser(desc) }
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default ProductModal