const initState = {
    news: null,
    loading: false,
    error: null,
  };

  const provinceReducer = (state =  initState, action) => {
      switch(action.type) {
            case 'SET_NEWS': 
                console.log('payload', action)
                return {
                    ...state,
                    news: action.payload
                }
            case 'GET_NEWS_SUCCESS': 
                console.log('payload', action)
                return {
                    ...state,
                    news: action.payload
                }
            case 'GET_NEWS_ERROR': 
                console.log('payload', action)
                return {
                    ...state,
                    news: null,
                    error: action.error
                }
            default:
                return state
      }
  }

  export default provinceReducer