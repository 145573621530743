import React from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";

import Home from "./page/Home/Home";

import store from "./redux/store";
import { Provider } from "react-redux";

class App extends React.Component {
  render() {
    return (
      <>
        {
          <Provider store={store}>
            <Router>
              <Route exact path="/" component={Home} />
            </Router>
          </Provider>
        }
      </>
    );
  }
}

export default App;

