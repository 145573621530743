import React, { Component } from "react";

function Navbar({ open }) {
  return (
    <div className="navbar-container">
      <div className="navbar-menu"><i className="fa fa-bars" onClick={() => open()}></i></div>
      <img src="https://staging-cuan.awalmula.co/pub/static/version1644573182/frontend/Magento/awalmula/id_ID/images/awalmula-logo-beta.png" alt="" />
    </div>
  );
}

export default Navbar;
