import React from 'react'

function Category({ id, parent_id, payload, choose }) {
  return (
    <div className='filter-list-container'>
      {
        payload && payload.map((items, index) => (
          <div key={index}>
            <div 
              className='title' 
              style={{color: `${id == items?.id || parent_id == items.id ? 'black' : 'grey'}`, cursor: 'pointer'}}
              onClick={() => choose(items?.id)}
            >
              { items?.name } ( { items?.product_count } )
            </div>
            <div className='subcategory-wrapper'>
              {
                items?.children_data && items.children_data.map((item, index2) => (
                  <div className='subcategory' key={index2} onClick={() => choose(item?.id, item?.parent_id)}>
                    <p style={{color: `${id == item?.id ? 'black' : 'grey'}`}}>{ item?.name } ( { item?.product_count } )</p>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Category