import React, { Component } from 'react'
import ProductCard from '../../components/card/product-card.jsx'
import Category from '../../components/category'
import MobileCategory from '../../components/mobile-category'
import Navbar from '../../components/NavBar/NavBar'
import Footer from '../../components/Footer/Footer'
import ReactPaginate from 'react-paginate';

import axios from 'axios'


class Home extends Component {
  constructor(props) {
    super(props)
    this.state= {
      products: [],
      categories: [],
      category_id: null,
      parent_id: null,
      isLoading: false,
      total_product: 0,
      select_total: 12,
      page: 0,
      currentPage: 0,
      isSideOpen: false,
    }
  }
  pageCount() {
    const total =  Math.ceil(parseInt(this.state.total_product) / parseInt(this.state.select_total))

    this.setState({
      page: total
    })
  }

  async getProduct() {
    const url = `https://staging-cuan.awalmula.co/rest/default/V1/products/?searchCriteria[pageSize]=${this.state.select_total}&searchCriteria[current_page]=${this.state.currentPage + 1}`

    this.setState({
      isLoading: true
    })
    try {
      const { data } = await axios({
        method: 'GET',
        url
      })
  
      this.setState({
        products: data?.items,
        isLoading: false,
        isSideOpen: false,
        total_product: data?.total_count
      }, () => this.pageCount())
    } catch ( error ) {
      console.log(error)
      this.setState({
        isLoading: false
      })
    }
  }

  async getProductWithCategory() {
    const url = `https://staging-cuan.awalmula.co/rest/default/V1/products/?searchCriteria[pageSize]=${this.state.select_total}&searchCriteria[filter_groups][0][filters][0][field]=category_id&searchCriteria[filter_groups][0][filters][0][value]=${this.state.category_id}&searchCriteria[current_page]=${this.state.currentPage}`
    this.setState({
      isLoading: true
    })
    try {
      const { data } = await axios({
        method: 'GET',
        url
      })
  
      this.setState({
        products: data?.items,
        total_product: data?.total_count,
        isSideOpen: false,
        isLoading: false
      },() => this.pageCount())
    } catch ( error ) {
      console.log(error)
    }
  }
  
  getAttributes(att, name) {
    const filter = att.filter(i => i.attribute_code == name)


    return filter[0]?.['value']
  }

  async getCategory() {
    const url = `https://staging-cuan.awalmula.co/rest/default/V1/categories`
    try {
      const { data } = await axios({
        method: "GET",
        url
      })

      this.setState({
        categories: data?.children_data
      })
    } catch ( error ) {
      console.log(error)
    }
  }

  chooseCategory(id, parent_id) {
    this.setState({
      category_id: id,
      parent_id,
      currentPage: 0
    }, () => this.getProductWithCategory())
  }

  setTotalProduct(event) {
    this.setState({
      select_total: event.target.value,
      currentPage: 0
    }, () => {
      if(this.state.category_id) {
        this.getProductWithCategory()
      } else {
        this.getProduct()
      }
    })
  }

  handlePageClick(data) {
    this.setState({
      currentPage: data.selected
    }, () => {
      if(this.state.category_id) {
        this.getProductWithCategory()
      } else {
        this.getProduct()
      }
    })
  }

  setMenuOpen() {
    this.setState({
      isSideOpen: !this.state.isSideOpen
    })
  }

  componentDidMount() {
    this.getProduct()
    this.getCategory()
  }
  render() {
    const { products, categories, category_id, parent_id, isLoading, isSideOpen } = this.state
    return (
      <div className='global-container'>
        {
          isLoading && (
            <div className='global-loading'>
              <img src="https://cutewallpaper.org/24/loading-gif-png/loading-6170a-gifs-b799f-on-e22ce-a-c1dc7-transparent-d7480-or-ed780-opaque-b0c85-background.gif" alt="" />
            </div>
          )
        }
        <Navbar 
          open={() => this.setMenuOpen()}
        />
        <div className='home-container'>
          <Category
            id={category_id}
            parent_id={parent_id}
            payload={categories}
            choose={(id, parent_id) => this.chooseCategory(id, parent_id)}
          />
          {
            isSideOpen && (
              <MobileCategory
                id={category_id}
                parent_id={parent_id}
                payload={categories}
                choose={(id, parent_id) => this.chooseCategory(id, parent_id)}
                close={() => this.setMenuOpen(0)}
              />
            )
          }
          
          <div className='product-list-container'>
            {
              products && products.map((item, index) => (
                <ProductCard 
                  key={index}
                  name={item.name}
                  img={this.getAttributes(item.custom_attributes, 'image')}
                  brand={JSON.parse(item?.extension_attributes?.brand)?.brand_name}
                  warehouse={
                    JSON.parse(item?.extension_attributes?.warehouse_data)?.name
                  }
                  price={item.price}
                  region={
                    JSON.parse(item?.extension_attributes?.warehouse_data)?.region
                  }
                  desc={this.getAttributes(item.custom_attributes, 'description')}
                />
              ))
            }
            <div className='pagination'>
              <div className="pagination-page">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  forcePage={this.state.currentPage}
                  onPageChange={(data) => this.handlePageClick(data)}
                  pageRangeDisplayed={3}
                  pageCount={this.state.page}
                  previousLabel="Prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination-container"
                  previousClassName='pagination-prev'
                  nextClassName='pagination-next'
                  activeClassName='pagination-active'
                  pageClassName='pagination-link'
                  breakClassName='pagination-break'
                />
              </div>
              <div className="pagination-total">
                <div className='pagination-text'>
                  Jumlah Produk
                </div>
                <div className='pagination-select'>
                  <select value={this.state.select_total} onChange={e => this.setTotalProduct(e)}>
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="50">50</option>
                  </select>
                </div>
                <div className='pagination-text'>Perhalaman</div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Home